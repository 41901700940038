import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import SEO from '../components/seo';
import Layout from '../components/Layout';

const Privacy = ({ className }) => {
  return (
    <Layout>
      <SEO
        title="Privacy"
        keywords={[`thecodesmith`, `privacy`, `policy`, `terms`]}
      />
      <section className={className}>
        <div className={className}>Privacy Page</div>
        <AniLink fade to="/">
          Back Home
        </AniLink>
      </section>
    </Layout>
  );
};

export default styled(Privacy)`
  color: white;
  min-height: 20rem;
  background: var(--mainEarth);
  text-align: center;
`;
